<template>
  <div class="clue-reassignment-detail-page">
    <van-form
      ref="formRef"
      scroll-to-error
      input-align="right"
      error-message-align="right"
      @submit="onSubmit"
    >
      <div class="cell">
        {{ $t("门店信息") }}
      </div>
      <van-cell-group inset>
        <van-cell :title="$t('已分配门店')" :value="$route.query.dealerName" />
        <van-cell
          :title="$t('省份城市')"
          :value="provinceName + cityName"
          is-link
          @click="onSelectProvinceCode"
        />
        <van-field
          required
          :label="$t('改派至门店')"
          :placeholder="$t('请选择门店')"
          :rules="[{ required: true }]"
          :value="newDealerName"
          is-link
          readonly
          :border="false"
          @click="onClick('newDealerId')"
        />
        <p
          v-if="newDealerId"
          style="padding-right: 16px;padding-bottom: 10px; color: rgba(69, 90, 100, 0.6); font-size: 12px; text-align: right;"
        >
          {{ `${$t("门店地址")}：${address}` }}
        </p>
        <van-field
          required
          :label="$t('改派原因')"
          :placeholder="$t('请选择改派原因')"
          :rules="[{ required: true }]"
          :value="reasonName"
          is-link
          readonly
          :border="false"
          rows="1"
          autosize
          type="textarea"
          @click="selectField"
        />
      </van-cell-group>

      <div class="cell required">
        <span>
          {{ $t("改派描述") }}
        </span>
      </div>
      <van-cell-group inset>
        <van-field
          v-model="remark"
          input-align="left"
          :placeholder="$t('请输入改派描述')"
          :rules="[{ required: true }]"
          rows="3"
          autosize
          type="textarea"
          maxlength="200"
          show-word-limit
        />
      </van-cell-group>
      <template>
        <div class="cell">
          {{ $t("补充材料") }}
        </div>
        <van-cell-group inset style="padding: 10px 8px 2px 16px">
          <van-uploader
            :fileList="fileList"
            accept="image/*"
            :preview-options="{ closeable: true }"
            :before-read="beforeRead"
            :after-read="(file) => afterRead(file)"
            :before-delete="(file) => beforeDelete(file)"
            :max-count="10"
          />
        </van-cell-group>
      </template>
      <div class="columns">
        <van-button type="info" round color="#EED484" @click="$router.back()">
          {{ $t("取消") }}
        </van-button>
        <van-button round native-type="submit">
          {{ $t("提交审核") }}
        </van-button>
      </div>
      <van-popup v-model="visible" position="bottom">
        <van-picker
          :loading="loading"
          show-toolbar
          :columns="columns"
          @change="onChange"
          @cancel="visible = false"
          @confirm="onConfirmArea"
        />
      </van-popup>
      <van-popup v-model="visibleDealer" position="bottom">
        <van-picker
          :loading="loading"
          show-toolbar
          :columns="dealerColumns"
          @cancel="visibleDealer = false"
          @confirm="onConfirmDealer"
        />
      </van-popup>
    </van-form>
  </div>
</template>
<script>
import { dealerTransferCCC } from '@/services/approveManage'
import baseDataServices from '@/services/baseDataServices'
import loading from '@/utils/loading.js'
import { uploadFile } from '@/utils/cos'
import { v4 as uuidv4 } from 'uuid'
import commonSelector from '@/components/common-selector'

export default {
  components: {},
  data() {
    return {
      remark: '',
      visible: false,
      columns: [{ values: [] }, { values: [] }],
      loading: false,
      items: [],
      visibleDealer: false,
      dealerColumns: [],
      provinceCode: '',
      provinceName: '',
      cityCode: '',
      cityName: '',
      newDealerName: '',
      fileList: [],
      address: '',
      newDealerId: '',
      transferReason: '',
      reasonName: '',
    }
  },
  methods: {
    async selectField() {
      const _obj = {
        dictType: 7001,
        multiple: true,
        min: 1
      }
      commonSelector(_obj).then((res) => {
        const arr = []
        this.reasonName = res
          .map(({ name, code }) => {
            arr.push(code)
            return name
          })
          .join()
        this.transferReason = arr.join()
      })
    },
    // 图片校验
    beforeRead(file) {
      const isLt10M = file.size / 1024 / 1024 > 20
      if (!file.type.includes('image') || isLt10M) {
        this.$toast.fail(this.$t('支持上传10张图片，单张最大20M'))
        return false
      }
      return true
    },
    afterRead(file) {
      const uuid = uuidv4()
      file.status = 'uploading'
      file.message = this.$t('上传中...')
      file.uuid = uuid
      uploadFile(file.file)
        .then(({ url, previewUrl }) => {
          if (file) {
            file.status = 'done'
            file.message = ''
            file.url = previewUrl
            file.cosUrl = url
            this.fileList.push(file)
          }
        })
        .catch(() => {
          file.status = 'failed'
          file.message = this.$t('上传失败')
        })
    },
    beforeDelete(file) {
      this.fileList = this.fileList.filter((item) => item.uuid !== file.uuid)
    },
    onConfirmDealer({ text, value, address }) {
      this.visibleDealer = false
      this.newDealerId = value
      this.newDealerName = text
      this.address = address
    },
    async onConfirmArea(items) {
      const [
        { value: provinceCode, text: provinceName },
        { value: cityCode, text: cityName } = {},
      ] = items
      this.provinceCode = provinceCode
      this.provinceName = provinceCode ? provinceName : ''
      this.cityCode = cityCode
      this.cityName = cityCode ? cityName : ''
      // const res = await this.getDealerList()
      this.newDealerId = ''
      this.newDealerName = ''
      this.address = ''
      this.visible = false
    },
    async onChange(e, items, index) {
      this.loading = true
      const [o] = items
      if (o.value !== this.items[0]?.value) {
        if (o.value) {
          const res = await this.getAreaList(items[0].value)
          this.columns.splice(1, 1, {
            values: [
              { text: this.$t('全部'), id: '' },
              ...res.map(({ name, id }) => ({
                text: name,
                value: id,
              })),
            ],
          })
        } else {
          this.columns.splice(1, 1, {
            values: [],
          })
        }
      }
      this.items = JSON.parse(JSON.stringify(items))
      this.loading = false
    },
    async onSelectProvinceCode() {
      this.visible = true
      this.columns = [
        {
          values: [
            { text: this.$t('全部'), id: '' },
            ...(await this.getAreaList(0)).map(({ name, id }) => ({
              text: name,
              value: id,
            })),
          ],
        },
        { values: [] },
      ]
    },
    async onSelectCityCode() {
      this.visible = true
      this.columns = await this.getAreaList(0)
    },
    // 获取省市区信息
    async getAreaList(parentId) {
      return await baseDataServices.getAreaList({ parentId })
    },
    async onSubmit() {
      const arr = []
      for (let index = 0; index < this.fileList.length; index++) {
        const { status, cosUrl } = this.fileList[index]
        if (status === 'done') {
          arr.push(cosUrl)
        } else if (status === 'uploading') {
          this.$toast.fail(this.$t('文件上传中，请稍后！'))
          return
        }
      }
      await this.$dialog.confirm({
        title: this.$t('提示'),
        message: this.$t('确定提交') + '?',
        confirmButtonColor: '#B9921A',
      })
      loading.showLoading()
      await dealerTransferCCC({
        leadId: this.$route.query.id,
        newDealerId: this.newDealerId,
        newDealerName: this.newDealerName,
        attachUrl: arr.join(),
        remark: this.remark,
        transferReason: this.transferReason,
      })
      loading.hideLoading()
      this.$toast(this.$t('操作成功'))
      this.$router.back()
    },
    async getDealerList() {
      const res = await baseDataServices.getDealerListApi({
        provinceCode: this.provinceCode,
        cityCode: this.cityCode,
        businessStatus: 10371002,
        isFictitious: 10041002,
        dealerType: 1
      })
      return res
    },
    async onClick() {
      this.visibleDealer = true
      const res = await this.getDealerList()
      this.dealerColumns = res.map((item) => ({
        text: item.name,
        value: item.id,
        ...item,
      }))
    },
    onConfirm() {
      this.visible = false
      this.$router.back()
    },
    onReview(result) {
      this.result = result
      this.visible = true
    },
  },
}
</script>
<style lang="less" scoped>
.clue-reassignment-detail-page {
  .clue-info {
    display: flex;
    align-items: center;
    .content {
      flex: 1;
    }
    i {
      margin: 0 16px;
    }
  }
  .cell {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 16px;
    &::before {
      content: "";
      background: #1989fa;
      width: 2px;
      height: 10px;
      margin-right: 4px;
      border-radius: 1px;
    }
    &.required {
      &::after {
        margin-left: 2px;
        color: #eed484;
        font-size: 14px;
        content: "*";
        align-self: baseline;
      }
    }
  }
  .user-info {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  .columns {
    padding: 16px;
    display: flex;
    justify-content: space-evenly;
    button {
      width: 40%;
    }
  }
}
</style>
